.border-art {
    border: 2px solid #864000;
    box-shadow: 0 0 10px #864000;
}

.border-context {
    border: 2px solid #c0927d;
    box-shadow: 0 0 10px #c0927d;
}

.border-place {
    border: 2px solid #cf6059;
    box-shadow: 0 0 10px #cf6059;
}

.border-stewards {
    border: 2px solid #939a79;
    box-shadow: 0 0 10px #939a79;
}

/* SMALL SCREEN */
@media (max-width: 768px) {
    .canvas {
        height: 78vh !important;
    }
}
