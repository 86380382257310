/* SMALL SCREEN */
@media (max-width: 768px) {
    .modal-overlay .modal-content .modal-image {
        max-width: 80%;
    }
}

/* BIG SCREEN */
@media (min-width: 768px) {
    .modal-overlay .modal-content .modal-image {
        max-width: 400px;
    }
}