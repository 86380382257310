.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  background-color: #000000;
  color: #ffffff;
}

.sidebar-border {
  border-right: 2px solid;
  border-image: linear-gradient(
    to bottom,
    #864000,
    #5abfc0,
    #dcb452,
    #fffed8,
    #c0927d,
    #cf6059
  ) 1;
}



@media (max-width: 768px) {
  .sidebar-border {
    border-right: none;
    border-bottom: 2px solid;
    border-image: linear-gradient(
      to right,
      #864000,
      #5abfc0,
      #dcb452,
      #fffed8,
      #c0927d,
      #cf6059
    ) 1;
  }

}


@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
  }

  .main-content {
    margin-left: 25%;
  }
  .sidebar {
    background-color: black;
  }
}


