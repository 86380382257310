body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}

/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Avenir:wght@400;500;600;700&display=swap');

.sidebar-font {
  font-family: 'Avenir', sans-serif;
  font-size: 25px;
  font-style: italic;
}

/* Define colors */
:root {
  --art-color: #864000;
  --about-color: #5abfc0;
  --altar-color: #dcb452;
  --collage-color: #fffed8;
  --context-color:#c0927d;
  --place-color:#cf6059;
  --stewards-color: #939a79;
}

.sidebar-link {
  text-decoration: none;
}

.sidebar-link:hover {
  text-decoration: underline !important;
}

.sidebar-link.color-art {
  color: var(--art-color);
}

.sidebar-link.color-about {
  color: var(--about-color);
}

.sidebar-link.color-altar {
  color: var(--altar-color);
}

.sidebar-link.color-collage {
  color: var(--collage-color);
}

.sidebar-link.color-context {
  color: var(--context-color);
}

.sidebar-link.color-place {
  color: var(--place-color);
}

.sidebar-link.color-stewards {
  color: var(--stewards-color);
}


.btn-dark.disabled {
  opacity: 0.3 !important;
  cursor: not-allowed;
}

.btn-light.disabled {
  opacity: 0.3 !important;
  cursor: not-allowed;
}







